export const AWSCertificationOptions = [
  {
    "label": "AWS Certified AI Practitioner",
    "id": "AIF-C01"
  },
  {
    "label": "AWS Certified Cloud Practitioner",
    "id": "CLF-C01"
  },
  {
    "label": "AWS Certified Developer – Associate",
    "id": "DVA-C01"
  },
  {
    "label": "AWS Certified Data Engineer – Associate",
    "id": "DEA-C01"
  },
  {
    "label": "AWS Certified SysOps Administrator – Associate",
    "id": "SOA-C01"
  },
  {
    "label": "AWS Certified Solutions Architect – Associate",
    "id": "SAA-C01"
  },
  {
    "label": "AWS Certified DevOps Engineer – Professional",
    "id": "DOP-C01"
  },
  {
    "label": "AWS Certified Solutions Architect – Professional",
    "id": "SAP-C01"
  },
  {
    "label": "AWS Certified Big Data – Specialty",
    "id": "BDS-C00"
  },
  {
    "label": "AWS Certified Advanced Networking – Specialty",
    "id": "ANS-C00"
  },
  {
    "label": "AWS Certified Security – Specialty",
    "id": "SCS-C01"
  },
  {
    "label": "AWS Certified Alexa Skill Builder – Specialty",
    "id": "AXS-C01"
  },
  {
    "label": "AWS Certified Machine Learning Engineer – Associate",
    "id": "MLA-C01"
  },
  {
    "label": "AWS Certified Machine Learning – Specialty",
    "id": "MLS-C01"
  },
  {
    "label": "AWS Certified Database – Specialty",
    "id": "DBS-C01"
  },
  {
    "label": "AWS Certified Data Analytics – Specialty",
    "id": "DAS-C01"
  },
  {
    "label": "AWS Certified SAP on AWS – Specialty",
    "id": "PAS-C01"
  }
];
